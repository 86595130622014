(function () {
  const propositionCount = 8;

  function getJsonld(proposition) {
    const jsonld = {
      "@context": "http://schema.org",
      "@type": "Apartment",
      photo: `url('${proposition.img}')`,
      url: `/${document.lang.inspiration_swap_path}/${proposition.id}`,
      numberOfRooms: proposition.rooms,
      floorSize: {
        "@type": "QuantitativeValue",
        value: proposition.sqm,
        unitCode: "MTK",
      },
      address: {
        "@type": "PostalAddress",
        streetAddress: proposition.address,
        addressLocality: proposition.city,
      },
      potentialAction: {
        "@type": "RentAction",
        priceSpecification: {
          "@type": "PriceSpecification",
          price: proposition.rent,
          priceCurrency: document.lang.inspiration_currency,
        },
      },
    };

    return JSON.stringify(jsonld);
  }

  function translatePropositions(propositions) {
    const translatedPropositions = propositions.map((proposition) => {
      return {
        id: proposition.ad_id,
        address: proposition.streetAddress,
        city: proposition.areaName,
        rooms: proposition.num_rooms,
        sqm: proposition.square_meter,
        rent: proposition.rent,
        img: proposition.filename,
      };
    });

    return translatedPropositions;
  }

  function fetchInspirationPropositions(count) {
    const inspirationUrl = document.lang.inspiration_url;
    return new Promise((resolve) => {
      fetch(`${inspirationUrl}?areaId=0&count=${count}`)
        .then((res) => res.json())
        .then((data) => {
          const propositions = translatePropositions(data["Object"]);
          resolve(propositions);
        });
    });
  }

  function renderPreloader(count) {
    const inspirationWrapper = document.querySelector(
      "#inspiration .inspiration-propositions"
    );

    inspirationWrapper.innerHTML = "";

    for (let x = 0; x < count; x++) {
      const placeholderEl = document.createElement("div");
      placeholderEl.classList.add("proposition");
      placeholderEl.classList.add("loading");
      placeholderEl.innerHTML = `
        <div class="proposition-image"></div>
        <div class="proposition-info">
          <div class="proposition-city block">texttext</div>
          <div class="proposition-address block">texttext</div>
          <div class="proposition-details">
            <p class="proposition-detail block">text</p>
            <p class="proposition-detail block">text</p>
          </div>
          <div class="proposition-price block">texttext</div>
        </div>
      `;
      inspirationWrapper.appendChild(placeholderEl);
    }
  }

  function resizeImage(image) {
    return image.replace("1600x1600", "800x800");
  }

  function renderInspirationPropositions(propositions) {
    const inspirationWrapper = document.querySelector(
      "#inspiration .inspiration-propositions"
    );

    inspirationWrapper.innerHTML = "";

    for (let x = 0; x < propositions.length; x++) {
      const proposition = propositions[x];

      const el = document.createElement("a");
      el.href = `/${document.lang.inspiration_swap_path}/${proposition.id}`;
      el.classList.add("proposition");
      el.innerHTML = `
        <script type="application/ld+json">
          ${getJsonld(proposition)}
        </script>
        <div class="aspect-ratio-box">
          <div
            class="aspect-ratio-box-inside proposition-image lazy"
            style="background-color: #fff; background-image: url(${resizeImage(
              proposition.img
            )})"
          ></div>
        </div>
        <div class="proposition-info">
          <div class="proposition-city">${proposition.city}</div>
          <div class="proposition-address">${proposition.address}</div>
          <div class="proposition-details">
            <p class="proposition-detail rooms">
              ${proposition.rooms} ${document.lang.inspiration_room}
            </p>
            <p class="proposition-detail sqm">${proposition.sqm} ${
              document.lang.inspiration_sqm
      }</p>
          </div>
          <div class="proposition-price">
            ${proposition.rent} ${document.lang.inspiration_price_per_month}
          </div>
        </div>
      `;
      inspirationWrapper.appendChild(el);
    }
  }

  renderPreloader(propositionCount);

  fetchInspirationPropositions(propositionCount).then(
    renderInspirationPropositions
  );
})();
