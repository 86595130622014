import { getCookie, deleteCookie } from "../../utils/cookies";

(function () {
  function setPropositionCountLabels() {
    const cookie = getCookie("activePropositions");
    if (cookie) {
      deleteCookie("activePropositions");
      const count = cookie.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      const propCount = document.getElementById("js-prop-count");
      if (propCount) propCount.innerText = count;

      const bulletCount = document.getElementById("js-prop-bullet-count");
      if (bulletCount) bulletCount.innerText = count;
    }
  }

  function displayAddress(addresses, index) {
    const addressElement = document.getElementById("js-swapped-address");
    if (!addressElement) return;

    addressElement.innerText = addresses[index];

    setTimeout(() => {
      let nextIndex = index + 1;
      if (nextIndex >= addresses.length) nextIndex = 0;

      displayAddress(addresses, nextIndex);
    }, 5000);
  }

  function getSwappedAddresses() {
    return new Promise((resolve) => {
      if (!window.location.hostname.endsWith(".se")) {
        return resolve([]);
      }

      fetch(
        "https://zkx9asjx1e.execute-api.eu-north-1.amazonaws.com/prod/swapped-addresses"
      )
        .then((res) => res.json())
        .then(({ swappedAddresses }) => {
          resolve(swappedAddresses);
        })
        .catch((e) => {
          console.log("Could not fetch swapped");
          resolve([]);
        });
    });
  }

  setPropositionCountLabels();

  getSwappedAddresses().then((addresses) => {
    displayAddress(addresses, 0);
  });
})();
