import { isAuthenticated } from "./authorization";

(function() {
  let startX = null;

  function checkTouchStartX(e) {
    if (!e.touches[0]) {
      return;
    }

    startX = e.touches[0].screenX;
  }

  function checkTouchEndX(e) {
    if (!e.changedTouches[0]) {
      return;
    }

    const endX = e.changedTouches[0].screenX;
    const movementX = startX - endX;
    if (movementX < -50) {
      closeMenu();
    }
  }

  function isInside(path, elements) {
    return Boolean(
      elements.find(element => {
        if (Array.isArray(path)) return path.indexOf(element) !== -1;
        return path === element;
      })
    );
  }

  function handleClickOutside({ event: e, elements = [], action }) {
    if (e.path || e.composedPath) {
      const path = Array.from(e.path || (e.composedPath && e.composedPath()));
      if (!path || isInside(path, elements)) return;
      action();
    } else {
      const app = document.getElementById("app");
      let path = e.target;

      while (path) {
        if (isInside(path, elements)) return;
        if (path === app) break;
        path = path.parentElement;
      }
      action();
    }
  }

  function setupSwipeFunctionality() {
    const menuDrawer = document.getElementById("js-mobile-menu");
    if (!menuDrawer) {
      return;
    }

    menuDrawer.addEventListener("touchstart", checkTouchStartX);
    menuDrawer.addEventListener("touchend", checkTouchEndX);
  }

  function setupAuthSwipeFunctionality() {
    const menuDrawer = document.getElementById("js-mobile-auth-menu");
    if (!menuDrawer) return;
    menuDrawer.addEventListener("touchstart", checkTouchStartX);
    menuDrawer.addEventListener("touchend", checkTouchEndX);
  }

  function destroySwipeFunctionality() {
    const menuDrawer = document.getElementById("js-mobile-menu");
    if (!menuDrawer) return;
    menuDrawer.removeEventListener("touchstart", checkTouchStartX);
    menuDrawer.removeEventListener("touchend", checkTouchEndX);
  }

  function destroyAuthSwipeFunctionality() {
    const menuDrawer = document.getElementById("js-mobile-auth-menu");
    if (!menuDrawer) return;
    menuDrawer.removeEventListener("touchstart", checkTouchStartX);
    menuDrawer.removeEventListener("touchend", checkTouchEndX);
  }

  function setUpAuthMenuData() {
    const userCache = JSON.parse(localStorage.getItem("userDataCache"));
    if (!userCache) return;

    const popupName = document.getElementById("js-popup-mobile-menu-name");
    const popupEmail = document.getElementById("js-popup-mobile-menu-email");
    const navAvatar = document.getElementById("js-popup-mobile-menu-avatar");

    popupName.innerText = userCache.name;
    popupEmail.innerText = userCache.email;
    navAvatar.style.backgroundImage = `url(${userCache.avatar})`;
  }

  function openMenu() {
    if (isAuthenticated()) {
      openAuthMenu();
      return;
    }

    document.getElementById("dark-overlay").classList.add("visible");
    document.getElementById("js-mobile-menu").classList.add("visible");
    setTimeout(setupSwipeFunctionality, 500);
  }

  function openAuthMenu() {
    document.getElementById("dark-overlay").classList.add("visible");
    document.getElementById("js-mobile-auth-menu").classList.add("visible");
    setTimeout(setupAuthSwipeFunctionality, 500);
    setTimeout(setUpAuthMenuData, 100);
  }

  function closeMenu() {
    destroySwipeFunctionality();
    destroyAuthSwipeFunctionality();
    document.getElementById("dark-overlay").classList.remove("visible");
    document.getElementById("js-mobile-menu").classList.remove("visible");
    const mobileAuthMenuEl = document.getElementById("js-mobile-auth-menu");
    if (mobileAuthMenuEl) {
      mobileAuthMenuEl.classList.remove("visible");
    }
  }

  function setElementsVisibility() {
    const authenticated = isAuthenticated();
    if (authenticated) {
      document.getElementById("nav").classList.add("hidden-auth-mobile");
      document.getElementById("nav-inner").classList.add("hidden-auth-mobile");
    }

    const authorizedElements = [
      "js-authenticated-navbar",
      "js-auth-tab-bar",
      "js-mobile-auth-menu"
    ];

    const unAuthorizedElements = ["js-unauthenticated-navbar"];

    const visibleElements = authenticated
      ? authorizedElements
      : unAuthorizedElements;

    const invisibleElements = authenticated
      ? unAuthorizedElements
      : authorizedElements;

    for (let i = 0; i < visibleElements.length; i++) {
      if (document.getElementById(visibleElements[i])) {
        document.getElementById(visibleElements[i]).classList.remove("hidden");
      }
    }

    for (let i = 0; i < invisibleElements.length; i++) {
      if (document.getElementById(invisibleElements[i])) {
        document.getElementById(invisibleElements[i]).classList.add("hidden");
      }
    }
  }

  function addEventlisteners() {
    const hamburgerMenu = document.querySelector("#nav-inner .hamburger");
    if (hamburgerMenu) {
      hamburgerMenu.addEventListener("touchend", openMenu);
      hamburgerMenu.addEventListener("click", openMenu);
    }
    const darkOverlay = document.querySelector(".dark-overlay");
    darkOverlay.addEventListener("touchend", closeMenu);
    darkOverlay.addEventListener("click", closeMenu);

    const avatarMenuLink = document.querySelector("#avatar-menu-link");
    if (avatarMenuLink) {
      avatarMenuLink.addEventListener("touchend", openMenu);
      avatarMenuLink.addEventListener("click", openMenu);
    }

    const popupMenuButton = document.getElementById("js-popup-toggle");
    if (popupMenuButton) {
      popupMenuButton.addEventListener("click", togglePopupMenu);
    }

    document.addEventListener("mouseup", closeOnClickOutside);
    setUpPopupMenuData();
  }

  function togglePopupMenu() {
    const popupMenu = document.getElementById("js-popup-menu");

    const classes = Array.from(popupMenu.classList);

    if (classes.indexOf("closed") > -1) {
      popupMenu.classList.remove("closed");
    } else {
      popupMenu.classList.add("closed");
    }
  }

  function closeOnClickOutside(e) {
    const menu = document.getElementById("js-popup-menu");
    const menuToggle = document.getElementById("js-popup-toggle");
    handleClickOutside({
      event: e,
      elements: [menu, menuToggle],
      action: function() {
        const popupMenu = document.getElementById("js-popup-menu");
        popupMenu.classList.add("closed");
      }
    });
  }

  function setUpPopupMenuData() {
    const userCache = JSON.parse(localStorage.getItem("userDataCache"));

    if (!userCache) return;

    const popupName = document.getElementById("js-popup-menu-name");
    const popupEmail = document.getElementById("js-popup-menu-email");
    const navAvatar = document.getElementById("js-popup-toggle");
    const popupAvatar = document.getElementById("js-popup-menu-avatar");
    const tabAvatar = document.getElementById("js-tab-bar-avatar");

    popupName.innerText = userCache.name;
    popupEmail.innerText = userCache.email;
    navAvatar.style.backgroundImage = `url(${userCache.avatar})`;
    popupAvatar.style.backgroundImage = `url(${userCache.avatar})`;
    tabAvatar.style.backgroundImage = `url(${userCache.avatar})`;
  }

  setElementsVisibility();
  addEventlisteners();
  closeMenu();
})();
