(function () {
  function getJsonld(blog) {
    return JSON.stringify({
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      articleBody: blog.content,
      url: blog.url,
      headline: blog.title,
      image: blog.img,
      datePublished: new Date(),
      author: {
        "@type": "Organization",
        name: document.lang.blog_organization_name,
      },
      dateModified: new Date(),
      publisher: {
        "@type": "Organization",
        name: document.lang.blog_author_name,
        logo: {
          "@type": "imageObject",
          url: document.lang.blog_url,
        },
      },
      mainEntityOfPage: false,
    });
  }

  function renderArticles(articles) {
    const blogWrapper = document.querySelector("#blog .blog-articles");
    blogWrapper.innerHTML = "";

    for (let x = 0; x < articles.length; x++) {
      const article = articles[x];

      const articleLink = document.createElement("a");
      articleLink.href = article.url;
      articleLink.classList = ["blog-card"];

      articleLink.innerHTML = `
        <script type="application/ld+json">
          ${getJsonld(article)}
        </script>
        <div class="blog-card-img number${x} lazy"></div>
        <h3 class="blog-card-title">${article.title}</h3>
        <p>${article.content}</p>
      `;

      blogWrapper.appendChild(articleLink);
    }
  }

  renderArticles([
    {
      title: document.lang.blog_article_title_1,
      url: document.lang.blog_article_path_1,
      content: document.lang.blog_article_content_1,
    },
    {
      title: document.lang.blog_article_title_2,
      url: document.lang.blog_article_path_2,
      content: document.lang.blog_article_content_2,
    },
  ]);
})();
